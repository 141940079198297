import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './Header.css';

function Header({ homeUrl }) {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <header className="app-header">
      <a href={homeUrl} className="logo-link">
        <img src="/images/blackbluelogo.png" alt="Parallels Logo" className="header-logo" />
      </a>
      <button className="hamburger-button" onClick={toggleMenu}>
        ☰
      </button>
      <nav className={menuOpen ? 'open' : ''}>
        <a href={homeUrl} onClick={toggleMenu}>Home</a>
        <Link to="/story" onClick={toggleMenu}>Story</Link>
        <Link to="/kyotishdictionary" onClick={toggleMenu}>Kyotish Dictionary</Link>
      </nav>
    </header>
  );
}

export default Header;
import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Header from './components/Header';
import Story from './components/Story';
import ProtectedStory from './components/ProtectedStory';
import KyotishDictionary from './components/KyotishDictionary';
import './App.css';
import './kyotish.css';

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(() => {
    return localStorage.getItem('isAuthenticated') === 'true';
  });

  const handleAuthentication = () => {
    setIsAuthenticated(true);
    localStorage.setItem('isAuthenticated', 'true');
  };

  return (
    <Router>
      <div className="App">
        <Header homeUrl="https://themethodparallels.com" />
        <Routes>
          <Route path="/" element={<Navigate to="/story" replace />} />
          <Route 
            path="/story" 
            element={
              <Story 
                isAuthenticated={isAuthenticated} 
                onAuthenticated={handleAuthentication}
              />
            } 
          />
          <Route path="/kyotishdictionary" element={<KyotishDictionary />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
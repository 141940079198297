import React, { useState, useCallback, useEffect } from 'react';
import './Story.css';
import ProtectedStory from './ProtectedStory';
import TypeformPayment from '../TypeformPayment';

function Story({ isAuthenticated, onAuthenticated }) {
  const [activeTab, setActiveTab] = useState('comic');
  const [currentPage, setCurrentPage] = useState(0);
  const [showProtected, setShowProtected] = useState(false);

  const pages = [
    {
      comic: '/images/cover.jpg',
      sketch: '/images/coversketch.jpg',
      commentary: {
        title: "Intro",
        text: "Sup guys,\n\nMy name is Adan. I'm a regular person like you who is trying to figure out this thing called life day by day. \n\nSome days are good, and other days could be better.  \n\nI've had many experiences in my life that I thought were unique, but when I look back at them, I realize that everyone has their own parallel experiences that have made them happy, sad, angry, excited, etc. \n\nIndeed, life moves on, and you will continue accumulating these experiences. \n\nThey are what make up your life. \n\nI wrote Parallels because I wanted people to relate to the main character, Ski, who, like all of us, faces many parallel experiences both as a kid and an adult. \n\nAll of these parallels have something in common. They are what we perceive them to be in our minds. \n\nIn other words, our mind is powerful. It controls how we handle situations, and it determines who we are and who we become. \n\nI hope that parallels will make you 1% more aware of the power of your mind. Trust me, you can achieve great things with it."
      }
    },
    {
      comic: '/images/comic-page-1.jpg',
      sketch: '/images/sketch-1.jpg',
      commentary: {
        title: "Starting with the end in mind",
        text: "I wanted this first page to show a sneak peek of Ski's future self, both in his younger and older versions. In both instances, he wakes up with a breakthrough that he has been working on for 245 days. \n\nWhen we start a new endeavor, we often need to start with the end in mind. \n\nKnowing precisely what we want our results to look like can motivate us to start. That is why I wanted this page to be at the beginning of the comic.\n\nStarting is usually tricky, so any way to make it easier is a plus. \n\nIs this page an actual moment in time? Or is it Ski's mind visualizing what could be a breakthrough in the future? Read on to find out."
      }
    },
    {
      comic: '/images/comic-page-2.jpg',
      sketch: '/images/sketch-2.jpg',
      commentary: {
        title: "Day 0 is the easiest pt 1",
        text: "This is the genesis of the series. Ski is beginning his journey in a new world. His dad is waiting for him and his mom on another planet, Sutato, and you can really see Ski's excitement.\n\nNew beginnings are exciting. We are moving away from our daily routine, and it's easy to imagine the possibilities that new endeavors may bring.\n\nIn truth, day 0 is easy because we have yet to actually start. We haven't been punched in the mouth yet."
      }
    },
    {
      comic: '/images/comic-page-3.jpg',
      sketch: '/images/sketch-3.jpg',
      commentary: {
        title: "Day 0 is the easiest pt 2",
        text: "In these illustrations, Ski begins a new chapter of his adult life. He is with his companion, Uchi, and they are ready to take on the world in Mizu, the world's capital. \n\nWhenever I start something new, I am excited for what's to come, similar to Ski. My mind races just thinking about the possibilities. Day 0 is easy because we can dream."
      }
    },
    {
      comic: '/images/comic-page-4.jpg',
      sketch: '/images/sketch-4.jpg',
      commentary: {
        title: "Day 0 is the easiest pt 3",
        text: "Part of the reason Day 0 is easy is that we have yet to face any obstacles. We are unconsciously incompetent; we don't know what we don't know, oblivious to possible obstacles.\n\nPeople often give the example of riding a bike being frustrating initially, but think about the day before you tried to learn to ride a bike. You were probably filled with nervous excitement. The mere thought of starting is thrilling.\n\nIn the same way, we are excited the day before our first driving lesson or the day before trying to launch a business. Day 0 is exciting but beware of Day 1."
      }
    },
    {
      comic: '/images/comic-page-5.jpg',
      sketch: '/images/sketch-5.jpg',
      commentary: {
        title: "Day 1 is the toughest",
        text: "Ski finally begins his new journey in Sutato, but there is a big problem: They speak Kyotish, a language he is unfamiliar with. \n\nStruggling is an understatement. Nothing seems to go right, and Ski feels like he will never be able to master Kyotish.\n\nThe actual start is when you get your hands dirty. It is also the most challenging part because you are what I like to call consciously incompetent. That is to say that you're rotten, and you know it. \n\nSki's mom, who is no stranger to difficult situations, gives him some wisdom. She is a comforting presence and a light in Ski's world."
      }
    },
    {
      comic: '/images/comic-page-6.jpg',
      sketch: '/images/sketch-6.jpg',
      commentary: {
        title: "Day 1 doesn't discriminate",
        text: "Older Ski has a rude awakening after launching his newest invention, which he thought would be an instant hit. Like most new businesses, when they launch, no one cares.\n\nWhen we're young, we think our parents have everything figured out. Then we get older and see that quite the opposite is true. In reality, everyone in the world is facing Day 1 in one way or another. Billionaire business owners, professional athletes, and, yes, even your parents are facing struggles. The reality is that day 1 doesn't care how old you are, what you look like, or where you come from. We all experience tough battles, especially when we are attempting new feats. \n\nOne thing that makes day 1 easier is surrounding yourself with people who support you. Get yourself a Uchi."
      }
    },
    {
      comic: '/images/comic-page-7.jpg',
      sketch: '/images/sketch-7.jpg',
      commentary: {
        title: "Day 1 is lonely",
        text: "The struggle for success is... a struggle, to say the least. In my life, it has often felt like I was attempting to sprint in quicksand. Pedaling fast and going nowhere.\n\nLet's face it. No one cares that we're facing day 1 because they are haggling with their own day 1. \n\nIn these challenging moments, we must dig deep and consider why we are doing what we're doing. Struggle is guaranteed in this uphill battle, but if we have a strong enough purpose for sticking it out, we can reach the finish line."
      }
    },
    {
      comic: '/images/comic-page-8.jpg',
      sketch: '/images/sketch-8.jpg',
      commentary: {
        title: "Day 1 repeats itself",
        text: "As a kid, it's learning a language that weighs Ski down. 24 years later, it's starting a business. At age 44, a different matter will take precedence.\n\nRemember the purpose from the last page? It's important because it will give you the energy to push past day 1. As a kid, as an adult, as a parent, and on every other occasion, purpose will pave the way."
      }
    },
    {
      comic: '/images/comic-page-9.jpg',
      sketch: '/images/sketch-9.jpg',
      commentary: {
        title: "The Grind",
        text: "Let me explain to you the infamous \"Grind.\"\n\nIt is the consistent daily actions you take to reach a desired goal. \n\nEach action gets you closer to or further away from your goal. But it's not perfect math; there is such a thing as doing too much. Ideally, you spend most of your time on actions that propel you forward but also mix in actions that make it worth it. After a hard work day, I occasionally treat myself to a succulent glass of red wine. Keep it fun, or else the \"Grind\" becomes the grind."
      }
    },
    {
      comic: '/images/comic-page-10.jpg',
      sketch: '/images/sketch-10.jpg',
      commentary: {
        title: "Purpose: The Grind's grandfather",
        text: "If there is no goal, there is no grind. If there is no purpose, there is no goal. It all works together.\n\nSki's purpose will ultimately push him to accomplish the actions he needs to take daily. If there's no purpose, he will likely grind for a bit and then quit because there is no motor behind the work. \n\nGoals are an excellent way to stay motivated, but like the grind, they can become stale if they differ from what we truly want. Know thyself!"
      }
    },
    {
      comic: '/images/comic-page-11.jpg',
      sketch: '/images/sketch-11.jpg',
      commentary: {
        title: "As long as you're alive, hold on to hope",
        text: "There will be times throughout life when we hit rock bottom. Failure, sickness, and rejection happen to many people daily. No one is safe from the ugly parts of life.\n\nThe good thing about rock bottom is that you can't go any lower. As long as you're breathing, day 2 will come. In these times, hold on to your purpose and use your mind to pull yourself out of this hole. Picture yourself where you want to be and use that motivation to get you through. The faster you can do this, the quicker you can turn the page.\n\nBut sometimes, help is necessary from those who have been there before..."
      }
    },
    {
      comic: '/images/comic-page-12.jpg',
      sketch: '/images/sketch-12.jpg',
      commentary: {
        title: "The Sensei",
        text: "There are two types of experiences.\n\nExpensive experience: Where you fail and learn from it.\n AND \nInexpensive experience: Where someone else failed, learned from it, then taught you before you could fail.\n\nYou need both types to succeed, but a sensei can speed up your transition from day 1 to day 2 through his expensive experience passed down to you. \n\nFind someone who has done what you want to do and make them your sensei. You'd be surprised at how far asking for help can take you."
      }
    },
    {
      comic: '/images/comic-page-13.jpg',
      sketch: '/images/sketch-13.jpg',
      commentary: {
        title: "The Sensei pt 2",
        text: "Having someone who has been through your path in your corner can significantly boost your progress. In times of need, they can provide a much-needed \"I've been there; it's going to be okay.\" You can see the light at the end of the tunnel through them.\n\nStill, a sensei is not going to do the work for you. Mentors are there to teach, motivate, and comfort, but the work is up to you.\n\nTip: the more driven you are to do the work, the more likely your sensei will help you."
      }
    },
    {
      comic: '/images/comic-page-14.jpg',
      sketch: '/images/sketch-14.jpg',
      commentary: {
        title: "Flow",
        text: "Once you get past day 1, you get to day 2. You become consciously competent, which means that you know you are good. You may even reach a state called flow or \"the zone.\" \n\n\"The zone\" is unconscious competence, and it feels like a superpower. Everything you do works. It is the complete opposite of day 1. \n\nReaching a flow state has been an age-long mystery; it comes and goes without saying anything. My theory is that the more you try, the more chances you will have to get into a flow state. Reps = chances."
      }
    },
    {
      comic: '/images/comic-page-15.jpg',
      sketch: '/images/sketch-15.jpg',
      commentary: {
        title: "Time",
        text: "I have a challenge for you. Measure your bicep today, then go to the gym and do as many curls as possible. After you're done, measure again. You won't see a difference. Furthermore, you may injure your bicep if you try to do too many curls.\n\nThe number of reps you do stops mattering at a certain point. If you're trying to learn math, there are only so many problems you can do in one day before it becomes unproductive. This is the law of diminishing returns.\n\nIt's easy to get impatient when you want to reach a certain goal. Don't try to fight time, it will forever be undefeated."
      }
    },
    {
      comic: '/images/comic-page-16.jpg',
      sketch: '/images/sketch-16.jpg',
      commentary: {
        title: "Time pt 2",
        text: "Time is inevitable. You must wait in line until it's your turn to reap the rewards. But don't worry, you will see results if you stay in line. \n\nStaying in line means putting in the reps daily—not excessively, but enough to improve by 1%. Rest, recover, and do it again.\n\nTime is like putting all those reps into a jar, filling them up to the top, and once you open that jar, you are magically good at what you were putting those reps into. It will seem like you wake up one day and magically be skilled."
      }
    },
    {
      comic: '/images/comic-page-17.jpg',
      sketch: '/images/sketch-17.jpg',
      commentary: {
        title: "Overnight success",
        text: "Indeed, to young Ski, it seems like he woke up and suddenly spoke fluent Kyotish. Comparable is his older self, whose business is taking off. \n\nThe truth is that these successes took time to happen. They happened through putting in the reps, being patient, and breaking through all the mental barriers preventing us from taking consistent action."
      }
    },
    {
      comic: '/images/comic-page-18.jpg',
      sketch: '/images/sketch-18.jpg',
      commentary: {
        title: "Key Lessons",
        text: "Looking back through this first chapter, I want to recall some key lessons that helped Ski:\nStarting is the toughest. If you can push through the beginning stages, you will have a better chance of succeeding.\nFind a Sensei. Someone who can guide you and speed up the process from average Joe to a pro.\nPut in consistent reps. Even if you start and find a mentor, you must act daily. No one will do it for you.\nGive it some time. The grind is laborious and lengthy. You will have to wait. Remember that.\n\nExtra: The journey is the destination\n\nThe most rewarding part about developing a new skill or doing something challenging is the experience. As I said at the beginning, experiences make up our lives. It is fun to set goals, and accomplishing them can give you a sense of temporary accomplishment, but then you go after the next goal. The journey, however, is what occupies most of your time. Make sure to enjoy it through the successes and the struggles. How do you enjoy it? That's for another chapter."
      }
    },
  ];

  const backgroundStyle = {
    backgroundImage: `url(${process.env.PUBLIC_URL}/images/gradient.jpg)`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundAttachment: 'fixed',
  };

  const changePage = useCallback((direction) => {
    setCurrentPage(prevPage => {
      const newPage = direction === 'next' 
        ? (prevPage + 1) % pages.length
        : (prevPage - 1 + pages.length) % pages.length;
      
      if (newPage > 1 && !isAuthenticated) {
        setShowProtected(true);
      }
      
      return newPage;
    });
  }, [isAuthenticated, pages.length]);

  const changeTab = (tab) => {
    setActiveTab(tab);
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'ArrowLeft') {
        changePage('prev');
      } else if (event.key === 'ArrowRight') {
        changePage('next');
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [changePage]);

  if (showProtected && !isAuthenticated) {
    return <ProtectedStory onAuthenticated={onAuthenticated} />;
  }

  const renderContent = () => {
    const currentContent = pages[currentPage];
    switch(activeTab) {
      case 'comic':
        return (
          <img 
            src={process.env.PUBLIC_URL + currentContent.comic} 
            alt={`Comic page ${currentPage}`} 
            className="story-image"
          />
        );
      case 'sketch':
        return (
          <img 
            src={process.env.PUBLIC_URL + currentContent.sketch} 
            alt={`Sketch page ${currentPage}`} 
            className="story-image"
          />
        );
      case 'commentary':
        return (
          <div className="commentary-content">
            <h2>{currentContent.commentary.title}</h2>
            <p>{currentContent.commentary.text}</p>
          </div>
        );
      default:
        return null;
    }
  };

  const getPageType = () => {
    return currentPage === 0 ? "Cover" : activeTab.charAt(0).toUpperCase() + activeTab.slice(1);
  };

  return (
    <div className="story-page" style={backgroundStyle}>
      <h1 className="story-title">THE PARALLELS STORY</h1>
      <div className="story-container">
        <div className="tab-navigation">
          <button 
            className={activeTab === 'comic' ? 'active' : ''} 
            onClick={() => changeTab('comic')}
          >
            COMIC
          </button>
          <button 
            className={activeTab === 'sketch' ? 'active' : ''} 
            onClick={() => changeTab('sketch')}
          >
            SKETCHES
          </button>
          <button 
            className={activeTab === 'commentary' ? 'active' : ''} 
            onClick={() => changeTab('commentary')}
          >
            COMMENTARY
          </button>
        </div>
        <div className="content-viewer">
          {renderContent()}
        </div>
        <div className="page-number">
          {getPageType()} Page {currentPage} of {pages.length - 1}
        </div>
      </div>
      <button className="arrow left-arrow" onClick={() => changePage('prev')}>&#8592;</button>
      <button className="arrow right-arrow" onClick={() => changePage('next')}>&#8594;</button>
    
      <div className="typeform-button">
        <TypeformPayment />
      </div>
    </div>
    

  );
}

export default Story;
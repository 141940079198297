import React, { useState, useRef, useEffect } from 'react';
import html2canvas from 'html2canvas';
import './KyotishDictionary.css';
import TypeformPayment from '../TypeformPayment';


function KyotishDictionary() {
  const [activeTab, setActiveTab] = useState('translate');
  const [englishText, setEnglishText] = useState('');
  const outputRef = useRef(null);
  const inputRef = useRef(null);

  const MAX_CHARS = 40;

  const englishToKyotish = {
    'a': 'icon-a', 'b': 'icon-b', 'c': 'icon-c', 'd': 'icon-d', 'e': 'icon-e',
    'f': 'icon-f', 'g': 'icon-g', 'h': 'icon-h', 'i': 'icon-i', 'j': 'icon-j',
    'k': 'icon-k', 'l': 'icon-l', 'm': 'icon-m', 'n': 'icon-n', 'o': 'icon-o',
    'p': 'icon-p', 'q': 'icon-q', 'r': 'icon-r', 's': 'icon-s', 't': 'icon-t',
    'u': 'icon-u', 'v': 'icon-v', 'w': 'icon-w', 'x': 'icon-x', 'y': 'icon-y',
    'z': 'icon-z', '?': 'icon-interr', '!': 'icon-exclam',
    '0': 'icon-0', '1': 'icon-1', '2': 'icon-2', '3': 'icon-3', '4': 'icon-4',
    '5': 'icon-5', '6': 'icon-6', '7': 'icon-7', '8': 'icon-8', '9': 'icon-9'
  };

  const backgroundStyle = {
    backgroundImage: `url(${process.env.PUBLIC_URL}/images/gradient.jpg)`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundAttachment: 'fixed',
  };

  useEffect(() => {
    const style = document.createElement('style');
    style.textContent = `
      .kyotish-text span {
        background-color: transparent !important;
        border: none;
        outline: none;
      }
      textarea, .kyotish-text {
        font-size: 24px;
        line-height: 1.5;
      }
      .kyotish-text span {
        font-size: 32px;
      }
    `;
    document.head.appendChild(style);
    return () => document.head.removeChild(style);
  }, []);

  const translateToKyotish = (text) => {
    return text.split('').map((char, index) =>
      englishToKyotish[char.toLowerCase()]
        ? <span key={index} className={englishToKyotish[char.toLowerCase()]}></span>
        : char
    );
  };

  const handleEnglishChange = (e) => {
    const newText = e.target.value.slice(0, MAX_CHARS);
    setEnglishText(newText);
  };

  const saveScreenshot = () => {
    if (outputRef.current && inputRef.current) {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');

      canvas.width = 1200;
      canvas.height = 800;

      const backgroundImage = new Image();
      backgroundImage.src = `${process.env.PUBLIC_URL}/images/stars.jpg`;

      backgroundImage.onload = () => {
        ctx.drawImage(backgroundImage, 0, 0, canvas.width, canvas.height);

        const captureAndDraw = (element, yOffset, scaleFactor, isEnglish = false) => {
          return html2canvas(element, {
            backgroundColor: null,
            scale: 2,
          }).then(textCanvas => {
            const scaledWidth = textCanvas.width * scaleFactor;
            const scaledHeight = textCanvas.height * scaleFactor;
            const x = (canvas.width - scaledWidth) / 2;
            const y = yOffset;

            if (isEnglish) {
              ctx.font = '48px Koulen';
              ctx.fillStyle = 'black';
              ctx.textAlign = 'center';
              ctx.fillText(element.value, canvas.width / 2, y + 60);
            } else {
              ctx.drawImage(textCanvas, x, y, scaledWidth, scaledHeight);
            }
          });
        };

        const englishYOffset = 300;
        const kyotishYOffset = 400;
        const scaleFactor = 0.9;

        Promise.all([
          captureAndDraw(inputRef.current, englishYOffset, scaleFactor, true),
          captureAndDraw(outputRef.current, kyotishYOffset, scaleFactor)
        ]).then(() => {
          const link = document.createElement('a');
          link.download = 'kyotish-translation.png';
          link.href = canvas.toDataURL('image/png');
          link.click();
        });
      };
    }
  };

  const renderTranslator = () => (
    <div className="translator">
      <div className="translation-container">
        <div className="input-area">
          <textarea
            ref={inputRef}
            value={englishText}
            onChange={handleEnglishChange}
            placeholder="TYPE IN ENGLISH HERE (MAX 40 CHARACTERS)"
            maxLength={MAX_CHARS}
          />
          <div className="character-count">{englishText.length} / {MAX_CHARS}</div>
        </div>
        <div className="output-area" ref={outputRef}>
          <div className="kyotish-text">
            {englishText === '' ? 'KYOTISH TRANSLATION' : translateToKyotish(englishText)}
          </div>
        </div>
      </div>
      <button onClick={saveScreenshot} className="download-btn">Save Translation</button>
    </div>
  );

  const renderAlphabet = () => (
    <div className="alphabet">
      <h2>Kyotish Alphabet</h2>
      <div className="alphabet-grid">
        {Object.entries(englishToKyotish).map(([english, kyotish]) => (
          <div key={english} className="alphabet-item">
            <span className="english-char">{english}</span>
            <span className={`kyotish-char ${kyotish}`}></span>
          </div>
        ))}
      </div>
    </div>
  );

  return (
    <div className="kyotish-dictionary-page" style={backgroundStyle}>
      <h1 className="dictionary-title">KYOTISH DICTIONARY</h1>
      <div className="kyotish-dictionary-container">
        <div className="tabs">
          <button
            className={`tab ${activeTab === 'translate' ? 'active' : ''}`}
            onClick={() => setActiveTab('translate')}
          >
            TRANSLATE
          </button>
          <button
            className={`tab ${activeTab === 'alphabet' ? 'active' : ''}`}
            onClick={() => setActiveTab('alphabet')}
          >
            ALPHABET
          </button>
        </div>

        <main>
          {activeTab === 'translate' ? renderTranslator() : renderAlphabet()}
        </main>
      </div>
      <div className="typeform-button">
        <TypeformPayment />
      </div>
    </div>
  );
}

export default KyotishDictionary;
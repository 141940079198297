import React, { useEffect } from 'react';

const TypeformPayment = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = "//embed.typeform.com/next/embed.js";
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div data-tf-live="01J23XF3074043THGZE5QN3G3E"></div>
  );
};

export default TypeformPayment;
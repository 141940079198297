import React, { useState, useEffect, useLayoutEffect } from 'react';
import './ProtectedStory.css';

function ProtectedStory({ onAuthenticated }) {
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const correctPassword = 'slellarap';

  const backgroundStyle = {
    backgroundImage: `url(${process.env.PUBLIC_URL}/images/gradient.jpg)`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundAttachment: 'fixed',
  };

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const script = document.createElement('script');
    script.src = "//embed.typeform.com/next/embed.js";
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const handlePasswordSubmit = (e) => {
    e.preventDefault();
    if (password === correctPassword) {
      localStorage.setItem('isAuthenticated', 'true');
      onAuthenticated();
    } else {
      alert('Incorrect password. Please try again or fill out the form.');
    }
  };

  return (
    <div className="protected-story" style={backgroundStyle}>
      <h1 className="story-title">THE PARALLELS STORY</h1>
      <div className="story-container">
        <div className="content">
          <h2>Access the Story</h2>
          <p>Enter the password or fill out the form to access the story.</p>
          
          <form onSubmit={handlePasswordSubmit}>
            <div className="password-input">
              <input
                type={showPassword ? "text" : "password"}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Enter password"
              />
              <button 
                type="button" 
                onClick={() => setShowPassword(!showPassword)}
                className={`toggle-password ${showPassword ? 'open' : ''}`}
                aria-label={showPassword ? "Hide password" : "Show password"}
              >
                <div className="eyelid"></div>
              </button>
            </div>
            <button type="submit" className="submit-button">Submit</button>
          </form>

          <h3>Or fill out this form:</h3>
          <div className="typeform-container">
            <div data-tf-live="01J2Z90VKCXFABWTGGTB3DTR3W"></div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProtectedStory;